import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import logo from "../../assets/css/images/foliologo.png"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import picture from "../../images/ecommerce-large.jpg"

export default () => (
  <Layout isHome={false} pageId="folio_single_item" logo={logo}>
    <SEO title="Ecommerce" />
    <div id="content">
      <div id="about_project">
        <h2 className="folio-title">Ecommerce</h2>
        <div className="contact-intro">
          We power the online sales of our clients. Our expertise is rooted in
          the years of experience building highly effective online marketplaces
          that stand above the crowd.
        </div>

        <h5>Retail performance</h5>
        <p>
          Our engineers fine-tune ecommerce implementations for stability,
          scalability, and performance. Even if we didn’t build your ecommerce
          site, we can host, improve, and maintain with our managed services.
        </p>
        <h5>The right fit for your business</h5>
        <p>
          We do it all, because we’ve done it all. Our expertise spans the range
          of ecommerce technologies — bespoke single-tenant applications,
          multi-store ecommerce platforms, and everything in between. No matter
          the scale of your business, we fit you with the right solution.
          <br />
          <strong>Magento</strong>: The number one large-scale open source
          system in the world.
          <br />
          <strong>Shopify</strong>: Get selling quickly with this popular
          ecommerce platform.
          <br />
          <strong>Drupal</strong>: This powerful platform is widely used and
          highly customizable.
        </p>
        <ul className="navig">
          <li className="prev">
            <AniLink fade to="/solutions/custom-applications" rel="prev">
              Custom Applications
            </AniLink>
          </li>
          <li className="next">
            <AniLink fade to="/solutions/managed-infrastructure" rel="next">
              Managed Infrastructure
            </AniLink>
          </li>
        </ul>
      </div>

      <div id="preview">
        <div className="blogimg slide image_1 current">
          <img
            src={picture}
            className="attachment-large-folio-thumb size-large-folio-thumb"
            alt="Ecommerce"
          />
        </div>
        <h5>Expertise to set you apart</h5>
        <p>
          Our ecommerce consultants will work closely with you to design
          cost-effective, sales-accelerating solutions that give you a
          competitive advantage. We have the expertise to deliver a solution
          that fits your business, whether you’re starting from scratch or
          expanding an established brand.
          <br />
          <br />
          Our business is building and supporting online storefronts to help you
          get your products into more hands.
        </p>
      </div>
    </div>
  </Layout>
)
